<template>
  <div class="playground">
    <div class="playground__container">
      <!-- <iframe
        :src="gamesLink"
        frameborder="0"
        class="journeyIframe"
      ></iframe> -->

      <object
        :data="gamesLink"
        width="100%"
        style="height: calc(100vh - 110px);"
      />

      <!-- <img
        src="@/assets/images/jrn.png"
        style="width: 100%;height: 100%;object-fit: cover;"
        alt="Journey"
      > -->

      <!-- <div class="playground__container__items__categories">
          <AppCategoryItem
            v-for="(category, indexCategories) in categories"
            :key="indexCategories"
            :name="category.name"
            :iconSrc="category.iconSrc"
            :bgColor="category.bgColor"
            :color="category.color"
            class="playground__container__items__categories__item"
            @click.native="selectCategory(indexCategories)"
          />
        </div>
        <div class="playground__container__items__products">
          <AppPlaygroundGame
            v-for="(game, index) in this.games"
            :key="index"
            :id="game.id"
            :name="game.name"
            :image="game.image"
            style="display: none;"
          />
        </div> -->
    </div>
    <!-- <div class="playground__sidebar">
      <div class="playground__sidebar__spendWallet">
        <AppPlaygroundWallet :points="getSecretStorePoints" />
      </div>
    </div> -->
    <AppSonikStore
      v-if="modal == true || $store.state.gamesResponse == true"
      :points="getSecretStorePoints"
      @close="closeModal"
    />
  </div>
</template>

<script>
// import AppPlaygroundWallet from "@/components/AppPlaygroundWallet";
// import AppCategoryItem from "@/components/AppCategoryItem";
// import AppPlaygroundGame from "@/components/AppPlaygroundGame";
import AppSonikStore from "../../components/AppSonikStore";
import { mapActions, mapGetters } from "vuex";
import config from "../../config";
import axios from "axios";

export default {
  components: {
    // AppPlaygroundWallet,
    // AppCategoryItem,
    AppSonikStore,
  },
  data() {
    return {
      games: [],
      modal: false,
      gamesIndex: 0,
      walletPoints: 1200,
      selectedCategoryGamesIndex: 0,
      categories: [
        {
          id: 0,
          name: "All skills",
          iconSrc: "test_icon.png",
          bgColor: "#36D5F2",
        },
        {
          id: 1,
          name: "Mental",
          iconSrc: "test_icon.png",
          bgColor: "#FFFFFF",
          games: [
            {
              id: 1,
              name: "Quick Math Test",
              image: "game_image.png",
            },
            {
              id: 2,
              name: "Card memory",
              image: "game_image.png",
            },
          ],
        },
        {
          id: 2,
          name: "Visual",
          iconSrc: "test_icon.png",
          bgColor: "#FFFFFF",
          games: [
            {
              id: 3,
              name: "Sonik Hangman",
              image: "game_image.png",
            },
          ],
        },
      ],
    };
  },
  created() {
    const user = {
      userId: localStorage.getItem("children_user_id"),
      token: localStorage.getItem("children_token"),
      family_id_check: localStorage.getItem("children_family_id"),
    };
    this.setupUser(user);
    this.fetchSecretStore();
    // console.log(
    //   "--------------------------------------------------------------------------"
    // );
    // console.log(this.gamesLink);

    this.gamesInterval = setInterval(
      function () {
        this.gamesAnswer();
      }.bind(this),
      2000
    );
    return this.gamesAnswer();
  },
  mounted() {
    this.selectedCategoryGamesIndex = 0;
    this.$store.state.journey = true;
    this.games = this.categories[1].games;
  },
  beforeDestroy() {
    this.$store.state.journey = false;
    clearInterval(this.gamesInterval);
  },
  computed: {
    ...mapGetters(["getSecretStorePoints"]),
    gamesLink() {
      const token = localStorage.getItem("children_token");
      const userId = localStorage.getItem("children_user_id");

      return config.GAMES_URL + `?token=${token}&user_id=${userId}`;
    },
  },
  methods: {
    ...mapActions(["fetchSecretStore", "setupUser"]),
    closeModal() {
      this.modal = false;
      this.$store.state.gamesResponse = false;
    },
    gamesAnswer() {
      const userId = localStorage.getItem("children_user_id");

      axios
        .get(config.GAMES_URL + `modals/trigger/secret_store/${userId}`)
        .then((response) => {
          if (response.data.data == true) {
            this.fetchSecretStore();
            this.$store.state.gamesResponse = true;
          }
        })
        .catch((error) => {
          // console.log(error);
          throw error;
        });
    },
    selectCategory(index) {
      var i;

      if (index == 0) {
        var j;
        this.categories[0].bgColor = "#36D5F2";

        for (i = 1; i < this.categories.length; i++) {
          for (j = 0; j < this.categories[i].games.length; j++) {
            this.games[this.gamesIndex++] = this.categories[i].games[j];
          }
        }
      }
      for (i = 1; i < this.categories.length; i++) {
        if (i == index) {
          this.categories[0].bgColor = "#FFFFFF";
          this.categories[i].bgColor = "#36D5F2";
          this.games = this.categories[i].games;
          this.selectedCategoryGamesIndex = index;
        } else {
          this.categories[i].bgColor = "#FFFFFF";
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.responsive-map-container {
  position: relative;
  padding-bottom: 58%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;

  border-radius: 20px;
}

.responsive-map-container iframe,
.responsive-map-container object,
.responsive-map-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  // height: 50vh;
}

iframe {
  display: block; /* iframes are inline by default */
  background: #000;
  border: none; /* Reset default border */
  // height: 100vh; /* Viewport-relative units */
  // width: 100vw;
}

.journeyIframe {
  border: 0;
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>
